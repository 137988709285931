<template lang="pug">
  v-row
    v-col(cols="4" sm="3" md="2")
      v-autocomplete(
        v-model="orgId"
        label="Организация"
        :items="organizations"
        :disabled="$root.isDisabled('terminals_list') || $root.isDisabled('organizations') || isDisabledOrg"
        :loading="$root.isLoading('organizations')"
        item-value="id"
        item-text="name"
        outlined
        dense
      )
    v-col(cols="4" sm="3" md="2")
      select-tags(
        v-model="tags"
        label="Тэги"
        :items="tagsList"
        :disabled="$root.isDisabled('terminals_list') || $root.isDisabled('tags') || isDisabledTags"
        :loading="$root.isLoading('tags')"
      )
    v-col(cols="4" sm="3" md="2")
      v-autocomplete(
        v-model="version"
        label="Версия"
        :items="versions"
        item-value="name"
        item-text="name"
        :disabled="$root.isDisabled('terminals_list') || $root.isDisabled('versions')"
        :loading="$root.isLoading('versions')"
        outlined
        dense
      )
    v-col(cols="5" sm="3" md="3")
      v-switch.mt-0.pt-0(
        v-model="openpoints"
        label="Открытые точки"
        messages="Показать терминалы на открытых точках"
        dence
      )
    v-col(cols="4" sm="3" md="1")
      v-btn(
        block
        variant="secondary"
        outlined
        :disabled="$root.isDisabled('terminals_list')"
        @click="() => reset() && queryList(true)"
      ) Сбросить
    v-col(cols="4" sm="3" md="1")
      v-btn.white--text(
        block
        color="blue"
        :disabled="$root.isDisabled('terminals_list')"
        @click="queryList(true)"
      ) Применить
    
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';

export default {
  name: 'FilterTerminals',

  components: {
    SelectOrganizations: () =>
      import('@/components/terminals/controls/SelectOrganiztion'),
    SelectTags: () => import('@/components/terminals/controls/SelectTags'),
    SelectVersions: () =>
      import('@/components/terminals/controls/SelectVersions'),
  },

  props: {
    queryList: {
      type: Function,
      default: () => {},
    },
  },

  computed: {
    ...mapGetters('TERMINALS_ORGANIZATIONS', ['organizations']),

    ...mapGetters('TERMINALS_TAGS', {
      tagsList: 'tags',
    }),

    ...mapGetters('TERMINALS_VERSIONS', ['versions']),

    ...mapGettersMutations('TERMINALS_FILTER', {
      orgId: 'setFilterOrgId',
      version: 'setFilterVersion',
      tags: 'setFilterTags',
      openpoints: 'setFilterOpenpoints',
    }),

    isDisabledOrg() {
      return this.openpoints;
    },

    isDisabledTags() {
      return typeof this.orgId !== 'number' && !this.isDisabledOrg;
    },
  },

  watch: {
    orgId(value) {
      this.$store.dispatch('TERMINALS_TAGS/fetch', {
        orgId: value,
      });
    },

    openpoints(value) {
      this.reset();
      this.queryList(true);
    },
  },

  methods: {
    ...mapActions('TERMINALS_FILTER', ['reset']),
  },
};
</script>
